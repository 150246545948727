import React from 'react';
import logo from './assets/gri.svg';
import './app.css';

function App() {
  return (
    <div className="app">
      <div className="content">
        <div className="header">
          <div className="logo">
            <img src={logo} alt="goodmore.captial" className="logo"/>
          </div>
          <div class="navigation">
            <div class="prime">
              We are a thesis-driven early-stage fund investing in crypto startups and protocols, <a href="mailto:hello@gri.capital" className="sm-link sm-link_padding-bottom sm-link-standard">get in touch</a> if you are building something cool.
            </div>
          </div>
        </div>

        <div class="mail">
          <a href="mailto:hello@gri.capital" className="sm-link sm-link_padding-bottom sm-link-standard">hello@gri.capital</a>
        </div>

      </div>

    </div>
  );
}

export default App;
